import { useRef, useState } from 'react';
import Link from 'next/link';
import cn from 'classnames';
// Types
import { GetAllAmas_amas_entities } from 'api/ama/types/GetAllAmas';
import { GetAllHomePageData_getHomePageData_amas } from 'api/homePage/types/GetAllHomePageData';
import {
  GetStoreBySlug_store,
  GetStoreBySlug_store_amas,
} from 'api/store/types/GetStoreBySlug';
// Constants
import { ORDER_AMA } from 'constants/routes';
// Helpers
import { formatCurrencyString } from 'helpers/formatCurrencyString';
import { getStreamerName } from 'helpers/streams';
import { trackAddToCartAma } from 'helpers/metrics';
// Ui
import Image from 'ui/Image/Image';
import Icon from 'ui/Icon/Icon';

import styles from './AmaListItem.module.scss';

const activeAmaAttr = 'active-ama';

type StoreAma = GetStoreBySlug_store_amas & { store: GetStoreBySlug_store };

type AmaListItemProps = {
  ama:
    | GetAllAmas_amas_entities
    | GetAllHomePageData_getHomePageData_amas
    | StoreAma;
  type?: 'list' | 'carousel' | 'store';
  priorityImageLoading?: boolean;
};

const PLAY_ICON_NAME = 'playback';
const PAUSE_ICON_NAME = 'pause';

const AmaListItem = ({
  ama,
  type = 'list',
  priorityImageLoading,
}: AmaListItemProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const imageWrapperRef = useRef<HTMLDivElement | null>(null);
  const [isVideoVisible, setVideoVisibility] = useState<boolean>(false);

  const storeSlug = ama?.store?.slug || '';
  const storeName = getStreamerName(ama?.store);
  const amaVideoURL = ama?.videoURL || '';
  const amaPrice = Number(ama?.price || 0);
  const videoThumbnailURL = ama?.videoThumbnailURL;
  const isStoreType = type === 'store';
  const isCarouselType = type === 'carousel';
  const isListType = type === 'list';
  const withImagePreload = priorityImageLoading || isStoreType;

  const setVisibilityAttributes = (visibility: 'visible' | 'hidden') => {
    const playIcon = imageWrapperRef.current?.querySelector<SVGSVGElement>(
      `[data-icon-name=${PLAY_ICON_NAME}]`
    );
    const pauseIcon = imageWrapperRef.current?.querySelector<SVGSVGElement>(
      `[data-icon-name=${PAUSE_ICON_NAME}]`
    );

    if (playIcon && pauseIcon) {
      if (visibility === 'visible') {
        playIcon.style.display = '';
        pauseIcon.style.display = 'none';
        imageWrapperRef.current?.removeAttribute(activeAmaAttr);
      } else {
        playIcon.style.display = 'none';
        pauseIcon.style.display = 'block';
        imageWrapperRef.current?.setAttribute(activeAmaAttr, ama.id);
      }
    }
  };

  const handleVideoPlayToggle = () => {
    /**
     * Stop another active AMA video before starting a new one with
     * a help of HTML attributes
     */
    const activeAma = document.querySelector<HTMLDivElement>(
      `[${activeAmaAttr}]`
    );
    const isAnotherActiveAma =
      imageWrapperRef.current?.getAttribute(activeAmaAttr) !==
      activeAma?.getAttribute(activeAmaAttr);

    if (isAnotherActiveAma && activeAma) {
      const playIcon = activeAma.querySelector<SVGSVGElement>(
        `[data-icon-name=${PLAY_ICON_NAME}]`
      );
      const pauseIcon = activeAma.querySelector<SVGSVGElement>(
        `[data-icon-name=${PAUSE_ICON_NAME}]`
      );

      activeAma.removeAttribute(activeAmaAttr);
      activeAma.querySelector('video')?.pause();

      if (playIcon && pauseIcon) {
        playIcon.style.display = '';
        pauseIcon.style.display = 'none';
      }
    }

    if (isVideoVisible) {
      if (videoRef.current?.paused) {
        videoRef.current.play();
        setVisibilityAttributes('hidden');
      } else {
        videoRef.current?.pause();
        setVisibilityAttributes('visible');
      }
    } else {
      setVideoVisibility(true);
      setVisibilityAttributes('hidden');
    }
  };

  const handleVideoEnded = () => {
    setVisibilityAttributes('visible');
  };

  const onOrderAmaLinkClick = () => {
    trackAddToCartAma({
      id: ama.id,
      price: amaPrice,
      type: ama.type,
      slug: storeSlug,
      storeName,
    });
  };

  return (
    <div
      className={cn(styles.root, {
        [styles.carouselItem]: isCarouselType,
        [styles.storeType]: isStoreType,
        [styles.listType]: isListType,
      })}
    >
      <div
        className={styles.imageWrapper}
        ref={imageWrapperRef}
        onClick={handleVideoPlayToggle}
        onKeyPress={handleVideoPlayToggle}
        tabIndex={0}
        role="button"
        aria-label="Play Personal Video"
      >
        {videoThumbnailURL && !isVideoVisible && (
          <Image
            title={storeName}
            alt={storeName}
            src={videoThumbnailURL}
            placeholder="blur"
            priority={withImagePreload}
            sizes="(max-width: 768px) 50vw, (max-width: 991px) 33vw, 20vw"
            objectFit="cover"
            className={styles.image}
          />
        )}
        {!videoThumbnailURL && !isVideoVisible && (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video
            src={`${amaVideoURL}#t=0.001`}
            controls={false}
            className={styles.video}
          />
        )}
        <Icon name={PLAY_ICON_NAME} className={styles.playIcon} />
        <Icon name={PAUSE_ICON_NAME} className={styles.pauseIcon} />
        {isVideoVisible && (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video
            src={amaVideoURL}
            autoPlay
            playsInline
            controls={false}
            ref={videoRef}
            className={styles.video}
            onEnded={handleVideoEnded}
          />
        )}
      </div>

      {!isStoreType && (
        <div className={styles.infoBlock}>
          <Link href={`/${storeSlug}`} prefetch={false}>
            <a className={styles.athleteLink}>{storeName}</a>
          </Link>

          <div className={styles.orderWrapper}>
            <Link href={`${ORDER_AMA}/${storeSlug}`} prefetch={false}>
              <a
                className={styles.orderLink}
                onClick={onOrderAmaLinkClick}
                onKeyPress={onOrderAmaLinkClick}
                role="button"
                tabIndex={0}
              >
                {`Order - ${formatCurrencyString(amaPrice)}`}
              </a>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default AmaListItem;
