/* eslint-disable graphql/template-strings */
import { gql } from '@apollo/client';

export const MEDIA_POST_VIEWED = gql`
  mutation MediaPostViewed($postId: ID!) {
    mediaPostViewed(postId: $postId) {
      postId
      viewCount
    }
  }
`;

export const SEARCH_PERFORMED = gql`
  mutation SearchPerformed($input: SearchInput!) {
    searchPerformed(input: $input)
  }
`;
