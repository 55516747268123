import cn from 'classnames';
// Layouts
import ResolutionWrapper from 'layouts/ResolutionWrapper/ResolutionWrapper';
// Ui2
import BackgroundTitle from 'ui2/BackgroundTitle/BackgroundTitle';
import LinkButton from 'ui2/LinkButton/LinkButton';

import styles from './Section.module.scss';

type AllOrNone<T> = T | { [k in keyof T]?: never };

type LinkData = AllOrNone<{
  seeAllLink: string;
  seeAllLinkTitle: string;
}>;

type SectionProps = {
  title: string;
  backgroundTitle?: string;
  theme?: 'white' | 'black';
  className?: string;
} & LinkData;

const Section: React.FC<SectionProps> = ({
  title,
  seeAllLink,
  seeAllLinkTitle,
  backgroundTitle,
  theme = 'white',
  className,
  children,
}) => {
  const isExternalLink = seeAllLink?.startsWith('http');

  return (
    <section className={cn(styles.root, styles[`theme-${theme}`], className)}>
      {backgroundTitle && (
        <BackgroundTitle
          color={theme === 'white' ? 'black' : 'gold'}
          content={backgroundTitle}
        />
      )}
      <ResolutionWrapper>
        {title && <h2 className={styles.headerTitle}>{title}</h2>}

        <div
          className={cn(styles.body, {
            [styles.noHeader]: !title,
          })}
        >
          {children}
        </div>

        {seeAllLink && (
          <LinkButton
            color={theme === 'white' ? 'black' : 'white'}
            href={seeAllLink}
            className={styles.linkButton}
            xs="small"
            l="default"
            target={isExternalLink ? '_blank' : undefined}
            rel={isExternalLink ? 'noopener' : undefined}
          >
            {seeAllLinkTitle}
          </LinkButton>
        )}
      </ResolutionWrapper>
    </section>
  );
};

export default Section;
