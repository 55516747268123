/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SearchLocation {
  Amas = "Amas",
  Athletes = "Athletes",
  Experiences = "Experiences",
  Home = "Home",
  Memorabilia = "Memorabilia",
  Merch = "Merch",
  Organizations = "Organizations",
  Products = "Products",
  Streams = "Streams",
}

export enum SearchResultType {
  Athlete = "Athlete",
  ContentCreator = "ContentCreator",
  Experience = "Experience",
  MediaPost = "MediaPost",
  Memorabilia = "Memorabilia",
  Merch = "Merch",
  Organization = "Organization",
  Product = "Product",
  Stream = "Stream",
}

export interface SearchInput {
  term: string;
  location: SearchLocation;
  searchResult?: SearchResultInput | null;
}

export interface SearchResultInput {
  srType: SearchResultType;
  srId: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
