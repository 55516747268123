// Types
import { GetAllHomePageData_getHomePageData_mainBanners } from 'api/homePage/types/GetAllHomePageData';
// Components
import HeroshotItem from './components/HeroshotItem/HeroshotItem';
// Context
import { SearchBarContextProvider } from '../SearchBar/hook/useSearchBarContext';

import styles from './Heroshot.module.scss';

type HeroshotProps = {
  banners: GetAllHomePageData_getHomePageData_mainBanners[];
};

const Heroshot = ({ banners }: HeroshotProps) => {
  const firstItem = banners[0];

  return (
    <div className={styles.root}>
      <SearchBarContextProvider>
        {firstItem && <HeroshotItem data={firstItem} />}
      </SearchBarContextProvider>
    </div>
  );
};

export default Heroshot;
