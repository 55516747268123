import { useRef } from 'react';
import { useSetState } from 'react-use';
import Link from 'next/link';
// Types
import { GetAllHomePageData_getHomePageData_mainBanners } from 'api/homePage/types/GetAllHomePageData';
// Helpers
import { parseForInnerHTML } from 'helpers/textEditor';
// Ui
import Image from 'ui/Image/Image';
import Icon from 'ui/Icon/Icon';
// Components
import SearchBar from 'components/HomePage/components/SearchBar/SearchBar';

import styles from './HeroshotItem.module.scss';

type HeroshotItemProps = {
  data: GetAllHomePageData_getHomePageData_mainBanners;
};

const HeroshotItem = ({ data }: HeroshotItemProps) => {
  const [videoState, setVideoState] = useSetState({
    muted: true,
    playing: true,
  });

  const videoRef = useRef<HTMLVideoElement | null>(null);

  const { image, video, title, link, buttonContent, shouldLinkRedirect } = data;

  const { muted, playing } = videoState;

  const videoUrl = video?.url;

  const handleVideoPlayPauseToggle = () => {
    if (playing) {
      videoRef.current?.pause();
      setVideoState({ playing: false });
    } else {
      videoRef.current?.play();
      setVideoState({ playing: true });
    }
  };

  const handleVideoMuteUnmuteToggle = () => {
    if (videoRef.current) {
      if (muted) {
        videoRef.current.muted = false;
        setVideoState({ muted: false });
      } else {
        videoRef.current.muted = true;
        setVideoState({ muted: true });
      }
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.mediaWrapper}>
          {videoUrl && (
            <div className={styles.videoControls}>
              <button
                className={styles.videoControlButton}
                onClick={handleVideoPlayPauseToggle}
                aria-label={playing ? 'pause' : 'play'}
              >
                <Icon name={playing ? 'pause' : 'playback'} />
              </button>
              <button
                className={styles.videoControlButton}
                onClick={handleVideoMuteUnmuteToggle}
                aria-label={muted ? 'unmute' : 'mute'}
              >
                <Icon name={muted ? 'volume-off' : 'volume-high'} />
              </button>
            </div>
          )}
          {videoUrl ? (
            <video
              className={styles.video}
              disablePictureInPicture
              playsInline
              controls={false}
              ref={videoRef}
              loop
              src={videoUrl}
              autoPlay
              muted
            />
          ) : (
            <Image
              className={styles.image}
              src={image?.url || ''}
              alt="MILLIONS"
              objectFit="cover"
              priority={true}
              placeholder="blur"
              sizes="50vw"
            />
          )}
        </div>
        <div className={styles.content}>
          <div
            className={styles.title}
            dangerouslySetInnerHTML={{
              __html: parseForInnerHTML(title),
            }}
          />

          <div className={styles.searchBarWrapper}>
            <SearchBar />
          </div>

          {link && buttonContent && (
            <Link href={link} prefetch={false}>
              <a
                className={styles.link}
                target={shouldLinkRedirect ? '_blank' : '_self'}
                rel={shouldLinkRedirect ? 'noopener noreferrer' : undefined}
              >
                {buttonContent}
              </a>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeroshotItem;
