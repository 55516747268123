export const formatCurrencyString = (
  value: number | string | undefined | null,
  prefix = '$',
  float = 2,
  currencyName = prefix ? 'USD' : ''
): string => {
  return `${prefix}${Number(value || 0).toLocaleString('en-US', {
    minimumFractionDigits: float,
    maximumFractionDigits: float,
  })} ${currencyName}`.trim();
};

export const formatCurrencyStringWithoutName = (
  value: number | string | undefined | null,
  prefix = '$',
  float = 2
): string => {
  return `${prefix}${Number(value || 0).toLocaleString('en-US', {
    minimumFractionDigits: float,
    maximumFractionDigits: float,
  })}`;
};
