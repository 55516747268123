import { useEffect } from 'react';
import { ApolloQueryResult } from '@apollo/client';
// Hooks
import { useGetCurrUser } from 'hooks';

type UseRefetchOnUserLogInProps = {
  refetch: (
    variables?: Partial<any> | undefined
  ) => Promise<ApolloQueryResult<any>>;
};

export const useRefetchOnUserLogIn = ({
  refetch,
}: UseRefetchOnUserLogInProps): null => {
  const { data: userData } = useGetCurrUser();
  const userId = userData?.me.id;

  useEffect(() => {
    if (userId) {
      refetch();
    }
  }, [userId, refetch]);

  return null;
};
