import cn from 'classnames';
// Types
import { GetStores_stores_entities } from 'api/store/types/GetStores';
import { GetAllHomePageData_getHomePageData_stores } from 'api/homePage/types/GetAllHomePageData';
// Components
import StoreListItem from './components/StoreListItem/StoreListItem';
import CarouselWithArrows from 'components/HomePage/components/common/CarouselWithArrows/CarouselWithArrows';
// Styles
import styles from './StoresList.module.scss';

type StoresListProps = {
  stores:
    | GetStores_stores_entities[]
    | GetAllHomePageData_getHomePageData_stores[];
  className?: string;
  storeImageLazyLoading?: boolean;
  withCarousel?: boolean;
};

const StoresList = ({
  stores,
  className,
  storeImageLazyLoading,
  withCarousel,
}: StoresListProps) => {
  if (!stores?.length) {
    return null;
  }

  if (withCarousel) {
    return (
      <div className={styles.rootCarousel}>
        <CarouselWithArrows>
          {stores.map(
            (
              store:
                | GetStores_stores_entities
                | GetAllHomePageData_getHomePageData_stores
            ) => (
              <StoreListItem
                key={store.id}
                store={store}
                storeImageLazyLoading={storeImageLazyLoading}
                type="carousel"
              />
            )
          )}
        </CarouselWithArrows>
      </div>
    );
  }

  return (
    <ul className={cn(styles.root, className)}>
      {stores.map(
        (
          store:
            | GetStores_stores_entities
            | GetAllHomePageData_getHomePageData_stores
        ) => (
          <li key={store.id}>
            <StoreListItem
              store={store}
              storeImageLazyLoading={storeImageLazyLoading}
            />
          </li>
        )
      )}
    </ul>
  );
};

export default StoresList;
