import dayjs from 'dayjs';
// Helpers
import { getDateInOriginalTimeZone } from 'helpers/timeZone';
import { computeOrderStreamPath, computeWatchStreamPath } from 'helpers/routes';
// Types
import {
  GetStream_stream,
  GetStream_stream_store,
  GetStream_stream_store_storeDetails,
} from 'api/streams/types/GetStream';
import {
  GetAllAmas_amas_entities_store,
  GetAllAmas_amas_entities_store_storeDetails,
} from 'api/ama/types/GetAllAmas';
import {
  GetAllHomePageData_getHomePageData_merch_store,
  GetAllHomePageData_getHomePageData_watchStreams_store,
  GetAllHomePageData_getHomePageData_amas_store_storeDetails,
  GetAllHomePageData_getHomePageData_athleteStories_store,
} from 'api/homePage/types/GetAllHomePageData';
import { GetStoreBySlug_store } from 'api/store/types/GetStoreBySlug';
import { StreamComputeParams } from 'helpers/routes';
import {
  StreamIntervalEnum,
  StreamStatus,
  TimeZoneInput,
} from 'api/graphql-global-types';
import { MyStreams_myStreams_entities } from 'api/streams/types/MyStreams';
import { GetUpcomingStreams_streams_entities_store } from 'api/streams/types/GetUpcomingStreams';
import { GetMerchProducts_getMerchProducts_entities_store } from 'api/merch/types/GetMerchProducts';
import { MyOutcomingStreamOrders_myOutcomingStreamOrders_entities_stream } from 'api/streams/types/MyOutcomingStreamOrders';
import { HomepageSearch_streamsV2_entities_store } from 'api/search/types/HomepageSearch';

type Stream =
  | GetStream_stream
  | MyStreams_myStreams_entities
  | MyOutcomingStreamOrders_myOutcomingStreamOrders_entities_stream
  | undefined;

export const isStreamDisabled = (stream: Stream): boolean => {
  return (
    stream?.streamStatus === StreamStatus.Cancelled ||
    stream?.streamStatus === StreamStatus.Ended
  );
};

export const isStreamUnableToEdit = (stream: Stream): boolean => {
  return (
    (stream?.streamStatus !== StreamStatus.Scheduled &&
      stream?.streamStatus !== StreamStatus.Ended) ||
    (stream?.streamStatus === StreamStatus.Ended && stream.isInterview)
  );
};

export const isStartStreamButtonDisabled = (stream: Stream): boolean => {
  const isStreamEndedOrCanceled =
    stream?.streamStatus === StreamStatus.Ended ||
    stream?.streamStatus === StreamStatus.Cancelled;

  if (isStreamEndedOrCanceled) {
    return true;
  }

  if (stream?.scheduleDate) {
    const currentTime = dayjs();
    const streamScheduleTime = dayjs(stream.scheduleDate).subtract(
      stream?.timeZone?.offset || 0,
      'minute'
    );
    const anHourBeforeStream = streamScheduleTime.subtract(1, 'hour');
    const isStreamShouldBeStartedSoon = currentTime.isAfter(anHourBeforeStream);

    return !isStreamShouldBeStartedSoon;
  }

  return false;
};

export const getStreamDate = (
  date: string | null,
  timeZone?: TimeZoneInput | null | undefined
): string => {
  if (date && dayjs(date).isValid()) {
    const dateString = getDateInOriginalTimeZone(date, 'MM/DD/YYYY [at] h:mmA');
    return `${dateString} ${timeZone?.tzCode || ''}`;
  }

  return '\u2014';
};

export const getPublicStreamDate = (
  date: string | null | undefined,
  timeZone?: TimeZoneInput | null | undefined
): any => {
  if (date && dayjs(date).isValid()) {
    const dateString = getDateInOriginalTimeZone(
      date,
      'MMMM D, YYYY [at] h:mmA'
    );
    return `${dateString}${timeZone?.tzCode ? ` ${timeZone?.tzCode}` : ''}`;
  }

  return '\u2014';
};

export const getStreamerName = (
  store:
    | GetStream_stream_store
    | GetAllAmas_amas_entities_store
    | GetUpcomingStreams_streams_entities_store
    | GetAllHomePageData_getHomePageData_merch_store
    | GetMerchProducts_getMerchProducts_entities_store
    | GetAllHomePageData_getHomePageData_watchStreams_store
    | GetStoreBySlug_store
    | GetAllHomePageData_getHomePageData_athleteStories_store
    | HomepageSearch_streamsV2_entities_store
    | null
    | undefined
): string => {
  const storeName = store?.storeDetails?.storeName;

  if (storeName) {
    return storeName;
  }

  return `${store?.firstName || ''} ${store?.lastName || ''}`.trim();
};

export const getStreamerAvatar = (
  storeDetails:
    | GetStream_stream_store_storeDetails
    | GetAllAmas_amas_entities_store_storeDetails
    | GetAllHomePageData_getHomePageData_amas_store_storeDetails
    | null
    | undefined
): string => {
  return storeDetails?.avatarURL || '/images/icons/avatar-placeholder.svg';
};

export const getTimeRemaining = (
  milliseconds: number
): {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  remainingLabel: string;
} => {
  const seconds = Math.floor((milliseconds / 1000) % 60);
  const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
  const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
  const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));

  const dayLabel = days ? `${days}d : ` : '';
  const remainingLabel =
    milliseconds > 0
      ? `${dayLabel}${hours}h : ${minutes}m : ${seconds}s`
      : 'Starting soon';

  return {
    days,
    hours,
    minutes,
    seconds,
    remainingLabel,
  };
};

export const getStreamLink = (stream: StreamComputeParams): string => {
  const streamIsPurchased = Boolean(stream?.isPurchased);
  const orderStreamPath = computeOrderStreamPath(stream);
  const watchStreamPath = computeWatchStreamPath(stream);

  return streamIsPurchased ? watchStreamPath : orderStreamPath;
};

export const getStreamSocialShareText = (
  isUserCreator: boolean,
  isPastStream: boolean,
  streamerName: string
): string => {
  if (isUserCreator) {
    return isPastStream
      ? 'Check out my newest video on @millionsdotco! Follow my profile on MILLIONS to get my latest content! #sports #MILLIONSco'
      : "Get ready to join me for an awesome live stream event on @millionsdotco! Let's connect and have fun together! Don't miss out! #livestream #sports #MILLIONSco";
  } else {
    return isPastStream
      ? `Check out this video from ${streamerName} on @millionsdotco! #sports #MILLIONSco`
      : `I’m joining ${streamerName} for their upcoming live stream event on @millionsdotco! Don't miss out, you can interact with ${streamerName} live! Come join me in attending it #livestream #sports #MILLIONSco`;
  }
};

export type RepeatStreamOption = {
  value: StreamIntervalEnum | null;
  label: string;
};

export const REPEAT_STREAM_OPTIONS: RepeatStreamOption[] = [
  {
    label: "No, it doesn't repeat",
    value: null,
  },
  {
    label: 'Every week',
    value: StreamIntervalEnum.weekly,
  },
  {
    label: 'Every other week',
    value: StreamIntervalEnum.biweekly,
  },
  {
    label: 'Every month',
    value: StreamIntervalEnum.monthly,
  },
];

export const formatHashtagInput = (hashtags) => {
  if (hashtags.length) {
    return hashtags.map((hashtag) => ({ name: hashtag }));
  } else {
    return null;
  }
};

export const getSoonestStream = (streams, id) => {
  let soonestStream;
  for (const stream of streams) {
    if (
      stream.scheduleDate &&
      stream.isInterview &&
      [StreamStatus.Scheduled, StreamStatus.Active].includes(
        stream.streamStatus
      )
    ) {
      const isHost = id === stream.interview.interviewer.id;
      if (isHost) {
        return;
      }
      const streamDateET = dayjs.tz(stream.scheduleDate, 'America/New_York');
      const streamDateUTC = streamDateET.utc();
      const streamDateLocal = new Date(streamDateUTC.format());

      if (
        streamDateLocal > new Date() ||
        (streamDateLocal < new Date() &&
          [StreamStatus.Active, StreamStatus.Scheduled].includes(
            stream.streamStatus
          ))
      ) {
        let soonestStreamSDateLocal;
        if (soonestStream) {
          const soonestStreamStartET = dayjs.tz(
            soonestStream.scheduleDate,
            'America/New_York'
          );
          const soonestStreamStartUTC = soonestStreamStartET.utc();
          soonestStreamSDateLocal = new Date(soonestStreamStartUTC.format());
        }
        if (!soonestStream || streamDateLocal < soonestStreamSDateLocal) {
          soonestStream = stream;
        }
      }
    }
  }
  return soonestStream;
};
