// Types
import { GetAllAmas_amas_entities } from 'api/ama/types/GetAllAmas';
import { GetAllHomePageData_getHomePageData_amas } from 'api/homePage/types/GetAllHomePageData';
// Components
import AmaListItem from './components/AmaListItem/AmaListItem';
import CarouselWithArrows from 'components/HomePage/components/common/CarouselWithArrows/CarouselWithArrows';

import styles from './AmaList.module.scss';

type AmaListProps = {
  amas: GetAllAmas_amas_entities[] | GetAllHomePageData_getHomePageData_amas[];
  withCarousel?: boolean;
};

type AmaItem =
  | GetAllAmas_amas_entities
  | GetAllHomePageData_getHomePageData_amas;

const AmaList = ({ amas, withCarousel }: AmaListProps) => {
  if (withCarousel) {
    return (
      <CarouselWithArrows>
        {amas.map((ama: AmaItem) => (
          <AmaListItem key={ama.id} ama={ama} type="carousel" />
        ))}
      </CarouselWithArrows>
    );
  }

  return (
    <ul className={styles.root}>
      {amas.map((ama: AmaItem, index: number) => (
        <li className={styles.listItem} key={ama.id}>
          <AmaListItem ama={ama} priorityImageLoading={index <= 2} />
        </li>
      ))}
    </ul>
  );
};

export default AmaList;
