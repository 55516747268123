import {
  useQuery,
  QueryResult,
  QueryOptions,
  WatchQueryFetchPolicy,
  QueryFunctionOptions,
} from '@apollo/client';
// Api
import { GET_SPORT_NAMES } from 'api/sports/queries';
// Types
import {
  GetSportNames,
  GetSportNamesVariables,
} from 'api/sports/types/GetSportNames';
import { GetSportsInput } from 'api/graphql-global-types';

type BasicQueryOptions = Omit<
  QueryOptions,
  'query' | 'variables' | 'fetchPolicy'
> & {
  fetchPolicy?: WatchQueryFetchPolicy;
} & QueryFunctionOptions;

type UseGetAthleteMerchProductsOptions = {
  options?: BasicQueryOptions;
  variables?: GetSportsInput;
};

export const useGetSportNames = (
  options?: UseGetAthleteMerchProductsOptions
): QueryResult<GetSportNames, GetSportNamesVariables> => {
  const sports = useQuery<GetSportNames, GetSportNamesVariables>(
    GET_SPORT_NAMES,
    {
      variables: {
        input: {
          ...options?.variables,
        },
      },
      ...options?.options,
    }
  );

  return sports;
};
