import { useMediaQuery } from 'react-responsive';

interface Breakpoints {
  // Desktop
  isBreakpointXXL: boolean;
  isBreakpointXL: boolean;
  // Tablet
  isBreakpointL: boolean;
  isBreakpointML: boolean;
  isBreakpointM: boolean;
  // Mobile
  isBreakpointS: boolean;
  isBreakpointXS: boolean;
}

// Desktop
export const BREAKPOINT_XXL = 1440;
export const BREAKPOINT_XL = 1280;
// Tablet
export const BREAKPOINT_L = 1024;
export const BREAKPOINT_ML = 991;
export const BREAKPOINT_M = 768;
// Mobile
export const BREAKPOINT_S = 512;
export const BREAKPOINT_XS = 0;

export const useBreakpoints = (): Breakpoints => ({
  // Desktop
  isBreakpointXXL: useMediaQuery({ minWidth: BREAKPOINT_XXL }),
  isBreakpointXL: useMediaQuery({ minWidth: BREAKPOINT_XL }),
  // Tablets
  isBreakpointL: useMediaQuery({ minWidth: BREAKPOINT_L }),
  isBreakpointML: useMediaQuery({ minWidth: BREAKPOINT_ML }),
  isBreakpointM: useMediaQuery({ minWidth: BREAKPOINT_M }),
  // Mobile
  isBreakpointS: useMediaQuery({ minWidth: BREAKPOINT_S }),
  isBreakpointXS: useMediaQuery({ minWidth: BREAKPOINT_XS }),
});
