import { NextPage } from 'next';
import { getDataFromTree } from '@apollo/client/react/ssr';
// Hocks
import withApollo from 'hocs/withApollo';
// Helpers
import { getEnvLink } from 'helpers/routes';
// Layouts
import Head from 'layouts/Head/Head';
import Header from 'layouts/Header/Header';
import Footer from 'layouts/Footer/Footer';
// Components
import HomePage from 'components/HomePage/HomePage';

const Home: NextPage = () => {
  const image = `${getEnvLink()}${'/homepage.jpg'}`;

  return (
    <>
      <Head
        image={image}
        title="MILLIONS.co - Connecting The Sports World"
        description="A social commerce platform where Fans, Brands, and Athletes unite. On MILLIONS, you can find athletes' exclusive channels where fans can purchase merchandise, get personal videos, shop memorabilia and experiences, and can connect with athletes on podcasts, streams, WatchParties, and other fan experiences."
      />
      <Header />
      <HomePage />
      <Footer />
    </>
  );
};

export default withApollo(Home, { getDataFromTree });
