// Components
import NavigationItem, {
  NavigationLink,
} from 'layouts/ProfileNavigation/components/NavigationSection/components/NavigationItem/NavigationItem';

import styles from './NavigationSection.module.scss';

type NavigationSectionProps = {
  title?: string;
  links: NavigationLink[];
};

const NavigationSection = ({ title, links }: NavigationSectionProps) => {
  return (
    <section className={styles.root}>
      {title && <span className={styles.title}>{title}</span>}

      <ul className={styles.list}>
        {links.map((item) => {
          return <NavigationItem key={item.label} item={item} />;
        })}
      </ul>
    </section>
  );
};

export default NavigationSection;
