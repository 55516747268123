import dayjs from 'dayjs';
// Types
import { GetAllHomePageData_getHomePageData_athleteStories } from 'api/homePage/types/GetAllHomePageData';
// Helpers
import { getStreamerName } from 'helpers/streams';
// Constants
import { BLOG } from 'constants/external-links';
// Components
import Section from 'components/common2/Section/Section';
import PreviewCards from 'components/common2/PreviewCards/PreviewCards';

type StoriesProps = {
  stories: GetAllHomePageData_getHomePageData_athleteStories[];
};

const Stories = ({ stories }: StoriesProps) => {
  if (!stories?.length) {
    return null;
  }

  const data = stories.map((item) => ({
    id: item.id,
    title: item.title,
    description: item.description,
    image: item.imageURL || '',
    meta: {
      link: item.store.slug || '',
      name: getStreamerName(item.store),
      date: dayjs(item.publishedDate).isValid()
        ? dayjs(item.publishedDate).format('MMMM D, YYYY')
        : '',
    },
    link: {
      href: item.link,
      text: 'Read story',
    },
    raw: item,
  }));

  return (
    <Section
      title="Athlete stories"
      backgroundTitle="stories"
      seeAllLink={BLOG}
      seeAllLinkTitle="View all stories"
    >
      <PreviewCards data={data} listSubtitle="Other athlete stories" />
    </Section>
  );
};

export default Stories;
