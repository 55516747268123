import { gql } from '@apollo/client';

export const AmaFields = gql`
  fragment AmaFields on Ama {
    id
    type
    price
    requestedPrice
    videoURL
    daysToResponse
    status
    videoThumbnailURL
    video {
      jobStatus
    }
  }
`;

export const AmasPublicFields = gql`
  fragment AmasPublicFields on Ama {
    id
    type
    price
    videoURL
    status
    videoThumbnailURL
  }
`;

export const AmaRequestFields = gql`
  fragment AmaRequestFields on AmaRequest {
    id
    ama {
      id
      type
    }
    amaId
    instructions
    mediaJobStatus
    orderStatus
    amaVideoURL
    declineReason
    createdAt
    dateToResponse
    customerId
    requestType
    email
    to
    from
    discountAmount
    price
    paymentMethodId
    stripeIntentClientSecret
    store {
      id
      slug
      lastName
      firstName
      email
    }
  }
`;

export const AmaSubmitFields = gql`
  fragment AmaSubmitFields on AmaRequest {
    id
    amaId
    instructions
    mediaJobStatus
    orderStatus
    amaVideoURL
    declineReason
    createdAt
    dateToResponse
    customerId
    requestType
    email
    to
    from
    discountAmount
    price
    paymentMethodId
    stripeIntentClientSecret
    store {
      id
      slug
      lastName
      firstName
      email
    }
  }
`;

export const AllAmaEntities = gql`
  fragment AllAmaEntities on Ama {
    status
    id
    type
    price
    store {
      id
      slug
      lastName
      firstName
      storeDetails {
        id
        storeName
        avatarURL
      }
    }
    videoURL
    videoThumbnailURL
  }
`;
