import { gql } from '@apollo/client';

export const HOMEPAGE_SEARCH = gql`
  query HomepageSearch($search: String) {
    stores(
      storeRoles: [Athlete, Organization, ContentCreator]
      input: {
        orderBy: storeName
        direction: ASC
        limit: 10
        searchTerm: $search
      }
    ) {
      entities {
        id
        lastName
        firstName
        role
        slug
        storeDetails {
          id
          storeName
        }
      }
    }
    streamsV2(
      input: {
        limit: 10
        direction: ASC
        streamStatus: [Active, Scheduled, Paused, Interrupted, Ended]
        storeStatus: Active
        searchTerm: $search
        withCopies: false
      }
    ) {
      entities {
        id
        name
        slug
        isPurchased
        store {
          id
          firstName
          lastName
          slug
          storeDetails {
            storeName
          }
        }
      }
    }
    getMerchProducts(
      input: { direction: ASC, limit: 10, searchTerm: $search }
    ) {
      entities {
        id
        title
        slug
        store {
          id
          slug
        }
      }
    }
    getMemorabilia(
      input: {
        direction: ASC
        limit: 10
        searchTerm: $search
        statuses: [Active]
      }
    ) {
      entities {
        id
        title
        slug
        memorabiliaProductType
        store {
          id
          slug
        }
      }
    }
    getExperiences(
      input: {
        direction: ASC
        limit: 10
        searchTerm: $search
        storeStatuses: [Active]
        statuses: [Active]
      }
    ) {
      entities {
        id
        title
        slug
        store {
          id
          slug
        }
      }
    }
    getMediaPosts(
      input: {
        direction: ASC
        limit: 10
        searchTerm: $search
        storeStatuses: [Active]
      }
    ) {
      entities {
        id
        title
        slug
        store {
          id
          slug
        }
      }
    }
  }
`;
