import { forwardRef } from 'react';
import cn from 'classnames';
import Slider, { Settings } from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './Carousel.module.scss';

type CarouselProps = Settings & { children: React.ReactNode };

const Carousel = forwardRef<Slider, CarouselProps>(
  ({ className, dots = true, arrows = false, children, ...props }, ref) => {
    return (
      <Slider
        ref={ref}
        className={cn(styles.carouselWrapper, className)}
        dots={dots}
        arrows={arrows}
        {...props}
      >
        {children}
      </Slider>
    );
  }
);

Carousel.displayName = 'Carousel';

export default Carousel;
