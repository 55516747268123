import cn from 'classnames';

import styles from './BackgroundTitle.module.scss';

export type BackgroundTitleProps = {
  color?: 'gold' | 'black' | 'white';
  content: string;
  withTopCutOff?: boolean;
  withBottomCutOff?: boolean;
  className?: string;
  doubled?: boolean;
};

const BackgroundTitle = ({
  color = 'black',
  content,
  withTopCutOff = true,
  withBottomCutOff = true,
  doubled,
  className,
}: BackgroundTitleProps) => {
  return (
    <div
      className={cn(
        styles.root,
        { [styles.withTopCutOff]: withTopCutOff },
        className
      )}
    >
      <p
        className={cn(styles.content, {
          [styles[`${color}`]]: color,
        })}
      >
        {content}
      </p>

      {doubled && (
        <p
          className={cn(styles.content, styles.contentDouble, {
            [styles[`${color}`]]: color,
            [styles.withTopCutOff]: withTopCutOff,
            [styles.withBottomCutOff]: withBottomCutOff,
          })}
        >
          {content}
        </p>
      )}
    </div>
  );
};

export default BackgroundTitle;
