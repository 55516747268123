import cn from 'classnames';
// Helpers
import { withCDN } from 'helpers/cdn';
// Layouts
import ResolutionWrapper from 'layouts/ResolutionWrapper/ResolutionWrapper';
// Ui
import Image from 'ui/Image/Image';
// Components
import CtaBannerActions from 'components/common2/CtaBannerActions/CtaBannerActions';
// Types
import { GetAllHomePageData_getHomePageData_ctaBanners } from 'api/homePage/types/GetAllHomePageData';
// Hooks
import { useGetCurrUser } from 'hooks';

import styles from './CtaSection.module.scss';

type CtaSectionProps = {
  cta: GetAllHomePageData_getHomePageData_ctaBanners;
  type: 'merch' | 'enroll';
};

const CtaSection = ({ cta, type }: CtaSectionProps) => {
  const { data } = useGetCurrUser();

  const isMerchType = type === 'merch';
  const isEnrollType = type === 'enroll';

  const isEnrollHidden = isEnrollType && data?.me?.id;

  const isCustomMerch = isMerchType && cta;

  const {
    title,
    content,
    buttonLink,
    buttonContent,
    link,
    linkContent,
    isBannerVisible,
    image,
  } = cta;

  const url = image?.url;
  const merchImage = url || withCDN('/images/home-page/cta-clothes.png');
  const enrollImage =
    url || withCDN('/images/home-page/cta-enroll-athlete.png');
  const imageURL = isMerchType ? merchImage : enrollImage;

  if (isEnrollHidden || !isBannerVisible) {
    return null;
  }

  return (
    <section
      className={cn(styles.root, {
        [styles.rootMerch]: isMerchType,
        [styles.rootCustomMerch]: isCustomMerch,
        [styles.rootEnroll]: isEnrollType,
      })}
    >
      <ResolutionWrapper className={styles.resolutionWrapper}>
        <div className={styles.contentWrapper}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.description}>{content}</p>
        </div>

        <div className={styles.imageRoot}>
          <div className={styles.imageWrapper}>
            <Image
              className={styles.image}
              src={imageURL}
              alt={title || 'Call to action image'}
              sizes="100vw"
            />
          </div>
        </div>

        {isMerchType && (
          <CtaBannerActions
            link={buttonLink || ''}
            buttonContent={buttonContent || ''}
            linkContent={linkContent || ''}
            type="merch"
          />
        )}

        {isEnrollType && (
          <CtaBannerActions
            link={link || '/'}
            linkContent={linkContent || ''}
            buttonContent={buttonContent || ''}
            type="enroll"
          />
        )}
      </ResolutionWrapper>
    </section>
  );
};

export default CtaSection;
