import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
// Api
import { CREATE_STREAM_ORDER } from 'api/streams/mutations';
// Types
import {
  CreateStreamOrder,
  CreateStreamOrderVariables,
} from 'api/streams/types/CreateStreamOrder';
import { GetUpcomingStreams_streams_entities } from 'api/streams/types/GetUpcomingStreams';
import { GetAllHomePageData_getHomePageData_watchStreams } from 'api/homePage/types/GetAllHomePageData';
import {
  GetAthleteStreams_liveAndUpcomingStreams_entities,
  GetAthleteStreams_pastStreams_entities,
} from 'api/streams/types/GetAthleteStreams';
import { GetStreams_streamsV2_entities } from 'api/streams/types/GetStreams';
import { GetStream_stream } from 'api/streams/types/GetStream';
// Hooks
import { useGetCurrUser } from 'hooks';
// Helpers
import { getStreamLink, getStreamerName } from 'helpers/streams';
import { trackAddToCartStream, trackStreamPurchase } from 'helpers/metrics';
import { computeWatchStreamPath } from 'helpers/routes';
// Components
import { showToast } from 'components/common/Toast/Toast';

type UseBookFreeStreamProps = {
  /**
   * Use `window.location.href` instead of `router.push` to solve `Connection closed` & `Client already in connecting/connected state` issues
   *
   * It's useful when we need to redirect a user from one stream page to another.
   */
  reloadOnRouteChange?: boolean;
};

export const useBookFreeStream = (props?: UseBookFreeStreamProps) => {
  const { push } = useRouter();

  const { data: userData } = useGetCurrUser();

  const { reloadOnRouteChange } = props || {};

  const [createStreamOrder] = useMutation<
    CreateStreamOrder,
    CreateStreamOrderVariables
  >(CREATE_STREAM_ORDER);

  const handleCreateStreamOrder = (
    stream?:
      | GetUpcomingStreams_streams_entities
      | GetAllHomePageData_getHomePageData_watchStreams
      | GetStreams_streamsV2_entities
      | GetAthleteStreams_liveAndUpcomingStreams_entities
      | GetAthleteStreams_pastStreams_entities
      | GetStream_stream
  ) => {
    const { id, slug, name, price, isPurchased, store } = stream || {};
    const watchStreamPath = computeWatchStreamPath(stream);
    const purchasedStreamLink = getStreamLink(stream);
    const streamId = id || '';
    const streamSlug = slug || '';
    const streamName = name || '';
    const streamPrice = price || 0;
    const storeName = getStreamerName(store);
    const isUsersStream = userData?.me?.id === store?.id;

    const trackAddToCart = () => {
      if (!isPurchased || !isUsersStream) {
        trackAddToCartStream({
          id: streamId,
          price: streamPrice,
          streamName,
          slug: streamSlug,
          storeName,
        });
      }
    };

    const trackPurchase = () => {
      trackStreamPurchase({
        id: streamId,
        price: 0,
        streamName: streamName,
        slug: streamSlug,
        storeName,
      });
    };

    const bookFreeStream = () => {
      try {
        createStreamOrder({
          variables: {
            input: {
              streamId,
            },
          },
        });

        trackAddToCart();
        trackPurchase();
      } catch (error) {
        showToast({
          message: `Looks like something went wrong${
            error?.message ? ` (${error?.message})` : ''
          }. Please try again`,
          type: 'error',
        });
      }
    };

    if (stream?.isFree) {
      if (userData && !isPurchased && !isUsersStream) {
        bookFreeStream();
      } else if (!userData) {
        // add a new tracking for guest users in case "trackPurchase" isn't the best option here
        trackPurchase();
      }

      if (reloadOnRouteChange) {
        window.location.assign(watchStreamPath);
      } else {
        push(watchStreamPath);
      }
    } else {
      trackAddToCart();

      if (reloadOnRouteChange) {
        window.location.assign(purchasedStreamLink);
      } else {
        push(purchasedStreamLink);
      }
    }
  };

  return handleCreateStreamOrder;
};
