import { useRef } from 'react';
import { useRouter } from 'next/router';
// Types
import { InputActionMeta } from 'react-select';
import { SearchResultType } from 'api/graphql-analytics-global-types';
// Constants
import { HOME, ATHLETES } from 'constants/routes';
// Hooks
import { useGetSportNames } from 'hooks';
// Context
import { useSearchBarContext } from './hook/useSearchBarContext';
// Ui2
import Select from 'ui2/Select/Select';
import Button from 'ui2/Button/Button';
// Styles
import styles from './SearchBar.module.scss';

export type SearchBarOptionProps = {
  name: string;
  searchTerm: string;
  value: string;
  label: string;
  url: string;
  id: string;
  type: SearchResultType;
};

export type SearchBarGroupProps = {
  label: string;
  options: SearchBarOptionProps[];
};

export type FilterOptionOption = {
  label: string;
  value: string;
  data: SearchBarOptionProps;
};

const SearchBar = () => {
  const searchBarWrapperRef = useRef<HTMLDivElement | null>(null);
  const { data: sportsData } = useGetSportNames({
    options: {
      ssr: false,
    },
  });

  const allSports = sportsData?.sports || [];

  const { push, pathname } = useRouter();

  const {
    searchValue,
    setSearchValue,
    options,
    loading,
    setSearchFocused,
    setSelectedOption,
  } = useSearchBarContext();

  const isHomepage = pathname === HOME;

  const optionValue = searchValue
    ? {
        label: searchValue,
        value: searchValue,
      }
    : null;

  const onInputChange = (value: string, action: InputActionMeta) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setSearchValue(value);

      return value;
    }

    if (action.action === 'input-blur') {
      setSearchValue(searchValue);

      return searchValue;
    }
  };

  const onSearchApply = (option: SearchBarOptionProps) => {
    // checking if option exists-in case of clear, it will be null, otherwise it will be an object
    if (option) {
      // send analytics when user selects an autocomplete option along with relevant data (search type and id)
      setSelectedOption(option);
    }
    setSearchValue(option.searchTerm);
    push(option.url);

    return option;
  };

  const navigateToAthletesPage = () => {
    const foundSport = allSports.find(
      (sport) => sport.name.toLowerCase() === searchValue.toLowerCase().trim()
    );

    if (!foundSport) {
      push(`${ATHLETES}?searchTerm=${searchValue}`);
    } else {
      push(`${ATHLETES}?sports=${foundSport.id}`);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      // setting focused false will trigger the analytics api without the timer
      setSearchFocused(false);

      navigateToAthletesPage();
    }
  };

  const commonSelectProps = {
    className: styles.searchBarSelect,
    placeholder:
      'Search for an athlete, organization, content creator, stream, product...',
    defaultOptions: true,
    getOptionLabel: (option: SearchBarOptionProps) => option.label,
    getOptionValue: (option: SearchBarOptionProps) => option.value,
    options,
    isLoading: loading,
    onInputChange,
    onChange: onSearchApply,
    onKeyDown,
    value: optionValue,
    defaultValue: optionValue,
    inputValue: searchValue,
    defaultInputValue: searchValue,
    cacheOptions: true,
    dropdownIndicator: null,
  } as const;

  if (!isHomepage) {
    return null;
  }

  return (
    <div className={styles.root} ref={searchBarWrapperRef}>
      <Select
        {...commonSelectProps}
        id="homepageSearch"
        inputId="homepageSearchInput"
        size="homepage"
        onFocus={() => setSearchFocused(true)}
        onBlur={() => setSearchFocused(false)}
        styles={{
          container: (provided) => ({
            ...provided,
            display: 'block',
          }),
          input: (provided) => ({
            ...provided,
            lineHeight: '20px',
            fontSize: '16px',
            paddingTop: '10px',
            paddingBottom: '10px',
            // $breakpoint_m
            '@media only screen and (min-width: 768px)': {
              lineHeight: '30px',
              fontSize: '24px',
              paddingTop: '16px',
              paddingBottom: '16px',
            },
          }),
          placeholder: (provided) => ({
            ...provided,
            textOverflow: 'ellipsis',
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            fontSize: '16px',
            // wrapper width minus button width & borders, minus 8px for 3 dots from ellipsis
            maxWidth: 'calc(360px - 106px - 8px)',
            // $breakpoint_m
            '@media only screen and (min-width: 768px)': {
              maxWidth: 'calc(600px - 106px - 8px)',
              fontSize: '24px',
            },
            // $breakpoint_ml
            '@media only screen and (min-width: 991px)': {
              maxWidth: `calc(800px - 138px - 8px)`,
              fontSize: '24px',
            },
          }),
          menu: (provided) => ({
            ...provided,
            width: searchBarWrapperRef.current?.offsetWidth,
            marginTop: 0,
            borderRadius: 0,
            zIndex: 3,
          }),
          valueContainer: (provided) => ({
            ...provided,
            textOverflow: 'ellipsis',
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            display: 'initial',
            textAlign: 'left',
            // wrapper width minus button width & borders,
            maxWidth: 'calc(360px - 106px)',
            // $breakpoint_m
            '@media only screen and (min-width: 768px)': {
              maxWidth: 'calc(600px - 106px)',
            },
            // $breakpoint_ml
            '@media only screen and (min-width: 991px)': {
              maxWidth: `calc(800px - 138px)`,
            },
          }),
          groupHeading: (provided) => ({
            ...provided,
            textAlign: 'left',
            fontSize: '16px',
            color: '#B6B6B6',
          }),
        }}
      />

      <Button
        color="harvest-gold"
        xs="small"
        ml="default"
        onClick={navigateToAthletesPage}
      >
        Search
      </Button>
    </div>
  );
};

export default SearchBar;
