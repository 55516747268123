// API
import { STREAMS } from 'constants/routes';
// Types
import { StreamForHomePage } from 'components/HomePage/HomePage';
// Hooks
import { useGetCurrUser, useBookFreeStream } from 'hooks';
// Helpers
import { formatCurrencyString } from 'helpers/formatCurrencyString';
import {
  getPublicStreamDate,
  getStreamLink,
  getStreamerName,
} from 'helpers/streams';
// Common
import Section from 'components/common2/Section/Section';
import PreviewCards, {
  PreviewCardItem,
} from 'components/common2/PreviewCards/PreviewCards';

type WatchstreamsProps = {
  streams: StreamForHomePage[];
  className?: string;
};

const Watchstreams = ({ streams }: WatchstreamsProps) => {
  const { data: userData } = useGetCurrUser();

  const bookFreeStream = useBookFreeStream();

  const getLinkText = (data: StreamForHomePage, index: number): string => {
    const isUsersStream = userData?.me?.id === data?.store?.id;

    return index === 0
      ? data.isPurchased || isUsersStream
        ? 'Watch this Stream'
        : data.isFree
        ? 'Register for Stream'
        : `Purchase Stream - ${formatCurrencyString(data.price)}`
      : !data.isPurchased && !isUsersStream && data.isFree
      ? 'Register for Stream'
      : data.isPurchased || isUsersStream
      ? 'Join the stream'
      : 'Purchase stream';
  };

  const previewCardsData = streams.map((item, index) => ({
    id: item.id,
    title: item.name || '',
    description: item.description,
    image: item.imageURL || '',
    meta: {
      name: getStreamerName(item.store),
      link: item.store?.slug || '',
      date: getPublicStreamDate(item.scheduleDate, item.timeZone),
    },
    link: {
      href: getStreamLink(item),
      text: getLinkText(item, index),
    },
    raw: item,
  }));

  const handleStreamLinkClick = (
    data: PreviewCardItem<StreamForHomePage>,
    e: React.SyntheticEvent<HTMLAnchorElement>
  ) => {
    e.preventDefault();

    bookFreeStream(data.raw);
  };

  if (!streams.length) {
    return null;
  }

  return (
    <Section
      title="Watchstream"
      backgroundTitle="streams"
      seeAllLink={STREAMS}
      seeAllLinkTitle="view all streams"
    >
      <PreviewCards<StreamForHomePage>
        data={previewCardsData}
        listSubtitle="Other WatchStreams"
        onLinkClick={handleStreamLinkClick}
      />
    </Section>
  );
};

export default Watchstreams;
