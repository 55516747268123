import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
// Types
import { GetAllMerchProducts_getMerchProducts_entities } from 'api/merch/types/GetAllMerchProducts';
import { GetMerchMinimalInfo_getMerchProducts_entities } from 'api/merch/types/GetMerchMinimalInfo';
// Helpers
import { formatCurrencyString } from 'helpers/formatCurrencyString';
import { computeMerchProductPath } from 'helpers/routes';
import { getStreamerName } from 'helpers/streams';
// Constants
import { ATHLETES } from 'constants/routes';
// Ui
import Image from 'ui/Image/Image';
// Styles
import styles from './MerchListItem.module.scss';

// "default" type for Home & List pages,
// "ad" - for Stream page
// "related" - for Store & Merch pages,
export type TypeMerchListItem = 'default' | 'ad' | 'related';

type MerchListItemProps = {
  merch:
    | GetAllMerchProducts_getMerchProducts_entities
    | GetMerchMinimalInfo_getMerchProducts_entities;
  imageLazyLoading?: boolean;
  openInNewTab?: boolean;
  type: TypeMerchListItem;
  className?: string;
  theme?: Theme;
};

const MerchListItem = ({
  merch,
  imageLazyLoading,
  openInNewTab,
  type,
  className,
  theme = 'white',
}: MerchListItemProps) => {
  const router = useRouter();

  const isDefault = type === 'default';
  const isAd = type === 'ad';
  const isRelated = type === 'related';
  const image = merch.mainImageUrl || '';
  const price = formatCurrencyString(merch.minPrice);
  const link = computeMerchProductPath(merch);
  const storeName = getStreamerName(merch?.store);

  const handleStoreButtonClick = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const url = merch.store?.slug ? `/${merch.store?.slug}` : ATHLETES;

    router.push(url);
  };

  return (
    <Link href={link} prefetch={false}>
      <a
        className={cn(
          styles.root,
          {
            [styles.default]: isDefault,
            [styles.ad]: isAd,
            [styles.related]: isRelated,
            [styles[`theme-${theme}`]]: theme,
          },
          className
        )}
        title={`"${merch.title}" by ${storeName}`}
        target={openInNewTab ? '_blank' : '_self'}
        rel={openInNewTab ? 'noopener noreferrer' : undefined}
      >
        <div className={styles.imageWrapper}>
          <Image
            className={styles.image}
            src={image}
            alt={merch.title}
            priority={!imageLazyLoading}
            placeholder="blur"
            sizes="(max-width: 768px) 50vw, (max-width: 991px) 33vw, 20vw"
          />
        </div>

        <div className={styles.infoWrapper}>
          {isDefault && (
            <button
              className={styles.storeButton}
              onClick={handleStoreButtonClick}
            >
              {storeName}
            </button>
          )}
          <p className={cn(styles.title, { [styles.large]: !isAd })}>
            {merch.title}
          </p>

          <div className={styles.infoFooter}>
            {isAd && (
              <>
                <div className={styles.infoFooterWrapper}>
                  <p className={cn(styles.price, { [styles.large]: !isAd })}>
                    {price}
                  </p>
                </div>
                <p className={styles.cta}>Buy Now</p>
              </>
            )}

            {!isAd && (
              <p className={styles.viewMoreLabel}>{`view - ${price}`}</p>
            )}
          </div>
        </div>
      </a>
    </Link>
  );
};

export default MerchListItem;
