// Types
import { GetAllHomePageData_getHomePageData_stores } from 'api/homePage/types/GetAllHomePageData';
// Constants
import { ATHLETES } from 'constants/routes';
// Components
import Section from 'components/common2/Section/Section';
import StoresList from 'components/common2/StoresList/StoresList';

type AthletesProps = {
  athletes: GetAllHomePageData_getHomePageData_stores[];
};

const Athletes = ({ athletes }: AthletesProps) => {
  if (!athletes?.length) {
    return null;
  }

  return (
    <Section
      title="Trending Athletes"
      backgroundTitle="Trending"
      seeAllLink={ATHLETES}
      seeAllLinkTitle="View all athletes"
    >
      <StoresList stores={athletes} storeImageLazyLoading withCarousel />
    </Section>
  );
};

export default Athletes;
