import Link from 'next/link';
import dayjs from 'dayjs';
// Constants
import {
  HOME,
  // SPORTS,
  ATHLETES,
  ORGANIZATIONS,
  AMAS,
  MERCH,
  STREAMS,
  PRIVACY_POLICY,
  TERMS_OF_SERVICE,
  FIND_YOUR_ORDER,
} from 'constants/routes';
// Constants
import { BLOG, CAREERS, ADVERTISE, ABOUT_US } from 'constants/external-links';
// Helpers
import { withCDN } from 'helpers/cdn';
// Layouts
import ResolutionWrapper from 'layouts/ResolutionWrapper/ResolutionWrapper';
// Ui
import Image from 'ui/Image/Image';
// Components
import SocialIcons from 'components/common2/SocialIcons/SocialIcons';
import CtaBannerActions from 'components/common2/CtaBannerActions/CtaBannerActions';
// Styles
import styles from './Footer.module.scss';

const PRODUCT_LINK = [
  {
    label: 'Personal Videos',
    link: AMAS,
  },
  {
    label: 'Streams',
    link: STREAMS,
  },
  {
    label: 'Merch',
    link: MERCH,
  },
  {
    label: 'Find your order',
    link: FIND_YOUR_ORDER,
  },
];

const NAVIGATION_ITEMS = [
  // {
  //   label: 'Sports',
  //   link: SPORTS,
  // },
  {
    label: 'Athletes',
    link: ATHLETES,
  },
  {
    label: 'Organizations',
    link: ORGANIZATIONS,
  },
];

const EXTERNAL_LINKS = [
  {
    label: 'About Us',
    link: ABOUT_US,
  },
  {
    label: 'Blog',
    link: BLOG,
  },
  {
    label: 'Careers',
    link: CAREERS,
  },
];

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <ResolutionWrapper>
        <div className={styles.mainWrapper}>
          <div className={styles.contentWrapper}>
            <div className={styles.logoWrapper}>
              <Link href={HOME} passHref prefetch={false}>
                <a>
                  <Image
                    className={styles.logo}
                    src={withCDN('/images/logos/mobile-logo-white.svg')}
                    alt="MILLIONS logo"
                    priority
                    unoptimized
                  />
                </a>
              </Link>
            </div>

            <div className={styles.navWrapper}>
              <nav className={styles.nav}>
                <div className={styles.linkWrapper}>
                  {PRODUCT_LINK.map((item) => {
                    return (
                      <Link href={item.link} key={item.label} prefetch={false}>
                        <a className={styles.link}>{item.label}</a>
                      </Link>
                    );
                  })}
                </div>
                <div className={styles.linkWrapper}>
                  {NAVIGATION_ITEMS.map((item) => {
                    return (
                      <Link href={item.link} key={item.label} prefetch={false}>
                        <a className={styles.link}>{item.label}</a>
                      </Link>
                    );
                  })}
                </div>
                <div className={styles.linkWrapper}>
                  {EXTERNAL_LINKS.map((item) => (
                    <a
                      rel="noopener noreferrer"
                      href={item.link}
                      key={item.link}
                      target="_blank"
                      className={styles.link}
                    >
                      {item.label}
                    </a>
                  ))}
                </div>
                <div className={styles.linkWrapper}>
                  <a
                    rel="noopener noreferrer"
                    href={ADVERTISE}
                    target="_blank"
                    className={styles.link}
                  >
                    Advertise
                  </a>
                </div>
              </nav>

              <div className={styles.contentButtons}>
                <SocialIcons />
              </div>
            </div>
          </div>

          <CtaBannerActions
            buttonContent="Enroll as Athlete"
            link="https://follow.millions.co/what-does-millions-do"
            linkContent="Why Join MILLIONS?"
            type="enroll"
          />
        </div>
      </ResolutionWrapper>

      <hr className={styles.divider} />

      <ResolutionWrapper>
        <div className={styles.additionalWrapper}>
          <div className={styles.privacyLinkWrapper}>
            <Link href={PRIVACY_POLICY} prefetch={false}>
              <a className={styles.privacyLink}>Privacy policy</a>
            </Link>
            <Link href={TERMS_OF_SERVICE} prefetch={false}>
              <a className={styles.privacyLink}>Terms of service</a>
            </Link>
          </div>
          <p className={styles.copyright}>© MILLIONS {dayjs().year()}</p>
        </div>
      </ResolutionWrapper>
    </footer>
  );
};

export default Footer;
