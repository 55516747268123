// Hooks
import { useAppContext, useGetCurrUser } from 'hooks';
// Ui2
import Button from 'ui2/Button/Button';
import LinkButton from 'ui2/LinkButton/LinkButton';

import styles from './CtaBannerActions.module.scss';

type CtaBannerActionsProps = {
  link: string;
  linkContent: string;
  buttonContent: string;
  type: 'merch' | 'enroll';
};

const CtaBannerActions = ({
  link,
  linkContent,
  buttonContent,
  type,
}: CtaBannerActionsProps) => {
  const { data } = useGetCurrUser();

  const isLoggedIn = Boolean(data?.me?.id);

  const { setAthleteSignUpModalVisibility } = useAppContext();

  const handleEnrollClick = () => {
    setAthleteSignUpModalVisibility(true);
  };

  return (
    <div className={styles.root}>
      {type == 'enroll' && !isLoggedIn && (
        <>
          <Button
            color="harvest-gold"
            onClick={handleEnrollClick}
            className={styles.enrollButton}
            xs="small"
            l="default"
          >
            {buttonContent}
          </Button>
          <a
            className={styles.whyJoinLink}
            href={link}
            rel="noopener noreferrer"
            target="_blank"
          >
            {linkContent}
          </a>
        </>
      )}

      {type == 'merch' && (
        <>
          <LinkButton
            xs="small"
            l="default"
            href={link || ''}
            color="harvest-gold"
            className={styles.enrollButton}
          >
            {buttonContent}
          </LinkButton>
          <p className={styles.whyJoinLink}>{linkContent}</p>{' '}
        </>
      )}
    </div>
  );
};

export default CtaBannerActions;
