import { forwardRef, useCallback } from 'react';
import cn from 'classnames';
import ReactSelect, {
  components,
  IndicatorProps,
  NamedProps,
  StylesConfig,
  OptionTypeBase,
} from 'react-select';
import { isMobile } from 'react-device-detect';
import ReactAsyncSelect, { AsyncProps } from 'react-select/async';
import { AsyncPaginate } from 'react-select-async-paginate';
// UI
import Icon, { IconNames } from 'ui/Icon/Icon';

import styles from './Select.module.scss';

/**
 * TODO: Add proper TS types after upgrading to the latest version.
 * Currently, it's not possible because of backward compatibility.
 */
type BasicSelectProps = NamedProps<OptionTypeBase, boolean> &
  (
    | { type?: 'default'; loadOptions?: never; cacheUniqs?: never }
    | ({ type: 'async' } & AsyncProps<OptionTypeBase>)
    | ({ type: 'async-paginate' } & Record<string, unknown>)
  );

type SelectProps = {
  error?: string | boolean;
  label?: string;
  labelClassName?: string;
  disabled?: boolean;
  notice?: string;
  dropdownIndicator?: IconNames | null;
  size?: 'default' | 'homepage';
} & BasicSelectProps;

const getControlBorderColor = (
  error: boolean,
  isFocused: boolean,
  disabled: boolean
): string => {
  if (error) {
    return '#dc2626';
  }

  if (isFocused) {
    return '#dddddd';
  }

  if (disabled) {
    return '#E5E5E5';
  }

  return '#e5e5e5';
};

const defaultFontStyles: React.CSSProperties = {
  fontFamily: 'GT America',
  fontSize: 18,
  lineHeight: '22px',
  fontWeight: 500,
};

const homepageFontStyles: React.CSSProperties = {
  ...defaultFontStyles,
  fontSize: 14,
  lineHeight: '16px',
};

const getCustomStyles = (
  disabled: boolean,
  error: boolean,
  size: SelectProps['size']
): StylesConfig<OptionTypeBase, boolean> => {
  const isSizeHomepage = size === 'homepage';
  const fontStyles = isSizeHomepage ? homepageFontStyles : defaultFontStyles;

  return {
    container: (provided) => ({
      ...provided,
      ...fontStyles,
      minWidth: 130,
      width: '100%',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#fff',
      border: '1px solid #DDDDDD',
      borderRadius: 0,
      boxShadow: 'none',
      overflow: 'hidden',
    }),
    option: (provided, { data: { disabled: isOptionDisabled } }) => {
      return {
        ...provided,
        ...fontStyles,
        margin: 0,
        padding: isSizeHomepage ? '10px 16px' : '18px 24px',
        backgroundColor: isOptionDisabled ? '#666666' : '#ffffff',
        textAlign: 'left',
        color: '#000',
        borderRadius: 0,
        '&:hover': {
          cursor: isOptionDisabled ? 'not-allowed' : 'pointer',
          backgroundColor: isOptionDisabled ? '#666666' : '#F4F4F4',
        },
      };
    },
    singleValue: (provided) => ({
      ...provided,
      ...fontStyles,
      margin: 0,
      color: disabled ? '#cccccc' : '#000',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      margin: 'auto',
      padding: 0,
      backgroundColor: disabled ? '#F4F4F4' : '#fff',
      justifyContent: 'flex-end',
      svg: {
        color: '#000',
        fontSize: 24,
      },
    }),
    loadingIndicator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    input: (provided) => ({
      ...provided,
      ...fontStyles,
      color: '#000',
      margin: 0,
      input: {
        ...fontStyles,
        /**
         * Backward compatibility
         *
         * TODO: investigate why we need hardcoded opacity
         * value and how to solve it a proper way
         */
        opacity: '1!important',
      },
    }),
    valueContainer: (provided, { selectProps: { isSearchable } }) => {
      const defaultPadding = isSizeHomepage
        ? '6px 0 6px 16px'
        : '18px 0 17px 24px';
      const searchablePadding = isSizeHomepage
        ? '6px 0 6px 16px'
        : '15px 0 15px 24px';

      return {
        ...provided,
        padding: isSearchable ? searchablePadding : defaultPadding,
      };
    },
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      borderRadius: 0,
    }),
    control: (provided, { isFocused }) => ({
      ...provided,
      minHeight: 'unset',
      paddingRight: 8,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: isSizeHomepage
        ? '#DDDDDD'
        : getControlBorderColor(error, isFocused, disabled),
      borderRadius: 0,
      backgroundColor: disabled ? '#F4F4F4' : '#fff',
      boxShadow: 'none',
      overflow: 'hidden',
      ':hover': {
        cursor: 'pointer',
        ...(isSizeHomepage
          ? {
              borderColor: '#DDDDDD',
            }
          : {
              borderColor: '#e5e5e5',
            }),
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      ...fontStyles,
      margin: 0,
      color: '#666',
    }),
  };
};

const Select = forwardRef(
  (
    {
      type = 'default',
      error,
      label,
      labelClassName,
      notice,
      className,
      inputId,
      dropdownIndicator,
      size,
      components: customComponents,
      styles: customStyles,
      ...rest
    }: SelectProps,
    ref
  ) => {
    const isDefaultType = type === 'default';
    const isAsyncType = type === 'async';
    const isAsyncPaginateType = type === 'async-paginate';
    const isError = Boolean(error);
    const isErrorLabelVisible = typeof error === 'string' && !!error.length;
    const isDisabled = !!rest.disabled || !!rest.isDisabled;

    const DropdownIndicator = useCallback(
      (props: IndicatorProps<OptionTypeBase, boolean>) => {
        if (dropdownIndicator === null) {
          return null;
        }

        return (
          <>
            <components.DropdownIndicator {...props}>
              <Icon name={dropdownIndicator || 'chevron-down'} />
            </components.DropdownIndicator>
          </>
        );
      },
      [dropdownIndicator]
    );

    const commonProps = {
      inputId,
      isDisabled,
      styles: {
        ...getCustomStyles(isDisabled, isError, size),
        ...customStyles,
      },
      isOptionDisabled: (option: any) => !!option.disabled,
      components: { DropdownIndicator, ...customComponents },
      inputRef: ref,
      menuShouldBlockScroll: isMobile,
      captureMenuScroll: false,
      ...rest,
    };

    return (
      <div className={cn(styles.root, className)}>
        {label && (
          <label htmlFor={inputId} className={cn(styles.label, labelClassName)}>
            {label}
          </label>
        )}

        {isDefaultType && <ReactSelect {...commonProps} />}

        {isAsyncType && <ReactAsyncSelect {...(commonProps as any)} />}

        {isAsyncPaginateType && <AsyncPaginate {...commonProps} />}

        {isErrorLabelVisible && (
          <span className={styles.errorLabel}>{error}</span>
        )}

        {notice && <span className={styles.noticeLabel}>{notice}</span>}
      </div>
    );
  }
);

Select.displayName = 'Select';

export default Select;
