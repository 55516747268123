import React from 'react';
// Types
import { GetAllHomePageData_getHomePageData_merch } from 'api/homePage/types/GetAllHomePageData';
// Constants
import { MERCH } from 'constants/routes';
// Components
import Section from 'components/common2/Section/Section';
import MerchList from 'components/common2/MerchList/MerchList';

type MerchProps = {
  merch: GetAllHomePageData_getHomePageData_merch[];
};

const Merch = ({ merch }: MerchProps) => {
  if (!merch?.length) {
    return null;
  }

  return (
    <Section
      title="Popular Merch"
      backgroundTitle="merch"
      seeAllLink={MERCH}
      seeAllLinkTitle="View all merch"
    >
      <MerchList merch={merch} storeImageLazyLoading type="carousel" />
    </Section>
  );
};

export default Merch;
