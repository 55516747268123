import cn from 'classnames';
// Types
import { GetAllHomePageData_getHomePageData_merch } from 'api/homePage/types/GetAllHomePageData';
import { GetMerchProducts_getMerchProducts_entities } from 'api/merch/types/GetMerchProducts';
// Components
import MerchListItem from './components/MerchListItem/MerchListItem';
import CarouselWithArrows from 'components/HomePage/components/common/CarouselWithArrows/CarouselWithArrows';
// Styles
import styles from './MerchList.module.scss';

// "carousel" type for Home page,
// "store" - for Store & Merch pages,
// "list" - for Listing & Search result pages,
// "ad" - for Stream page
export type TypeMerchList = 'carousel' | 'store' | 'list' | 'ad';

type MerchListProps = {
  merch: Array<
    | GetAllHomePageData_getHomePageData_merch
    | GetMerchProducts_getMerchProducts_entities
  >;
  storeImageLazyLoading?: boolean;
  openLinkInNewTab?: boolean;
  type: TypeMerchList;
};

const MerchList = ({
  merch,
  storeImageLazyLoading,
  openLinkInNewTab,
  type,
}: MerchListProps) => {
  const merchWithMockups = merch.filter((item) => item.status === 'active');

  const isCarousel = type === 'carousel';
  const isStore = type === 'store';
  const isList = type === 'list';
  const isAd = type === 'ad';

  const typeMerchListItem = isAd ? 'ad' : isStore ? 'related' : 'default';

  if (!merchWithMockups?.length) {
    return null;
  }

  if (isCarousel) {
    return (
      <div className={styles.rootCarousel}>
        <CarouselWithArrows>
          {merchWithMockups?.map((merch) => (
            <MerchListItem
              key={merch.id}
              merch={merch}
              openInNewTab={openLinkInNewTab}
              imageLazyLoading={storeImageLazyLoading}
              type={typeMerchListItem}
            />
          ))}
        </CarouselWithArrows>
      </div>
    );
  }

  return (
    <ul
      className={cn(styles.root, {
        [styles.store]: isStore,
        [styles.list]: isList,
        [styles.ad]: isAd,
      })}
    >
      {merchWithMockups?.map((merch) => (
        <li key={merch.id}>
          <MerchListItem
            merch={merch}
            openInNewTab={openLinkInNewTab}
            imageLazyLoading={storeImageLazyLoading}
            type={typeMerchListItem}
          />
        </li>
      ))}
    </ul>
  );
};

export default MerchList;
