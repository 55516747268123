/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Link from 'next/link';
import cn from 'classnames';
import { useRouter } from 'next/router';
// Ui
import LinkButton from 'ui2/LinkButton/LinkButton';
import Image from 'ui/Image/Image';
import Loader from 'ui2/Loader/Loader';
import EllipsisText from 'ui2/EllipsisText/EllipsisText';
// Styles
import styles from './PreviewCards.module.scss';

export type PreviewCardItem<T> = {
  id: string;
  title: string;
  description: string;
  image: string;
  meta: {
    name: string;
    link?: string;
    date: string;
  };
  link: {
    href: string;
    text: string;
    loading?: boolean;
  };
  raw: T;
};

type PreviewCardsProps<T> = {
  data?: PreviewCardItem<T>[];
  onLinkClick?: (
    data: PreviewCardItem<T>,
    e: React.SyntheticEvent<HTMLAnchorElement>
  ) => void;
  listSubtitle: string;
};

const InternalExternalLink: React.FC<{ link: string }> = ({
  link,
  children,
}) => {
  const isExternalLink = link.startsWith('http');

  if (isExternalLink) {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  return (
    <Link href={link} prefetch={false}>
      <a>{children}</a>
    </Link>
  );
};

const PreviewCards = <T extends Record<keyof T, unknown>>({
  data,
  onLinkClick,
  listSubtitle,
}: PreviewCardsProps<T>) => {
  const { push } = useRouter();

  if (!data?.length) {
    return null;
  }

  const firstCard = data[0];
  const restCards = data.slice(1, 3);

  const firstCardLink = firstCard.link.href;
  const isFirstCardLinkExternal = firstCardLink.startsWith('http');

  const handleLinkClick: PreviewCardsProps<T>['onLinkClick'] = (data, e) => {
    if (onLinkClick) {
      onLinkClick(data, e);
    }
  };

  const handleReadMoreClick = () => {
    push(firstCardLink);
  };

  return (
    <div className={styles.root}>
      <div className={styles.firstImageWrapper}>
        {firstCard.image && (
          <InternalExternalLink link={firstCardLink}>
            <Image
              src={firstCard.image}
              alt={firstCard.title}
              placeholder="blur"
              sizes="(max-width: 991px) 100vw, 50vw"
            />
          </InternalExternalLink>
        )}
      </div>
      <div className={styles.body}>
        <div className={styles.firstContent}>
          <p className={styles.firstMeta}>
            {firstCard.meta.link ? (
              <Link href={`/${firstCard.meta.link}`} prefetch={false}>
                <a className={styles.firstMetaName}>{firstCard.meta.name}</a>
              </Link>
            ) : (
              <span className={styles.firstMetaName}>
                {firstCard.meta.name}
              </span>
            )}
            {firstCard.meta.date && (
              <span>&nbsp;&bull;&nbsp;{firstCard.meta.date}</span>
            )}
          </p>
          <h3 className={styles.firstTitle}>{firstCard.title}</h3>
          <EllipsisText
            className={styles.firstDescription}
            text={firstCard.description}
            maxLine={{ xs: 4, ml: 3 }}
            onReadMoreClick={handleReadMoreClick}
          />
          <LinkButton
            color="black"
            href={firstCardLink}
            onClick={(e) => handleLinkClick(firstCard, e)}
            loading={firstCard.link.loading}
            xs="small"
            l="default"
            {...(isFirstCardLinkExternal
              ? {
                  target: '_blank',
                  rel: 'noopener noreferrer"',
                }
              : {})}
          >
            {firstCard.link.text}
          </LinkButton>
        </div>
        {!!restCards.length && (
          <div className={styles.cardList}>
            <p className={styles.cardListTitle}>{listSubtitle}</p>
            {restCards.map((item) => (
              <div className={styles.card} key={item.id}>
                <InternalExternalLink link={item.link.href}>
                  <Image
                    src={item.image}
                    className={styles.image}
                    alt={item.title}
                    placeholder="blur"
                    sizes="10vw"
                  />
                </InternalExternalLink>
                <div className={styles.entry}>
                  <div>
                    <InternalExternalLink link={item.link.href}>
                      <p className={styles.title}>{item.title}</p>
                    </InternalExternalLink>
                    <p className={styles.meta}>
                      {item.meta.link ? (
                        <Link href={`/${item.meta.link}`} prefetch={false}>
                          <a className={styles.metaName}>{item.meta.name}</a>
                        </Link>
                      ) : (
                        <span className={styles.metaName}>
                          {item.meta.name}
                        </span>
                      )}
                      {item.meta.date && (
                        <span>&nbsp;&bull;&nbsp;{item.meta.date}</span>
                      )}
                    </p>
                  </div>

                  {item.link.href.startsWith('http') ? (
                    <a
                      className={styles.link}
                      onClick={(e) => handleLinkClick(item, e)}
                      target="_blank"
                      href={item.link.href}
                      rel="noopener noreferrer"
                    >
                      {item.link.text}
                    </a>
                  ) : (
                    <Link href={item.link.href} prefetch={false}>
                      <a
                        className={cn(styles.link, {
                          [styles.loading]: item.link.loading,
                        })}
                        onClick={(e) => handleLinkClick(item, e)}
                      >
                        {item.link.text}
                        <Loader
                          loading={!!item.link.loading}
                          size="small"
                          color="harvest-gold"
                        />
                      </a>
                    </Link>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviewCards;
