import { useState } from 'react';
import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
// Constants
import { isActiveRoute } from 'helpers/routes';
// Ui
import Icon, { IconNames } from 'ui/Icon/Icon';

import styles from './NavigationItem.module.scss';

type LinksWithSubMenu = {
  icon: IconNames;
  label: string;
  href?: never;
  subMenu: {
    label: string;
    href: string;
  }[];
};

type LinksWithoutSubMenu = {
  icon: IconNames;
  label: string;
  href: string;
  subMenu?: never;
};

export type NavigationLink = LinksWithSubMenu | LinksWithoutSubMenu;

type NavigationItemProps = {
  item: NavigationLink;
};

const NavigationItem = ({ item }: NavigationItemProps) => {
  const { pathname } = useRouter();

  const { label, href, icon, subMenu } = item;

  const isActiveParentMenuLink = !!subMenu?.some((item) =>
    isActiveRoute(pathname, item.href)
  );

  const [isSubMenuVisible, setSubMenuVisibility] = useState(
    isActiveParentMenuLink
  );

  const handleSubMenuToggle = () => {
    setSubMenuVisibility((prev) => !prev);
  };

  return (
    <>
      {!!href && (
        <li
          className={cn(styles.listItem, {
            [styles.active]: isActiveRoute(pathname, href),
          })}
        >
          <Link href={href}>
            <a className={styles.link}>
              <Icon name={icon} className={styles.icon} />
              <span className={styles.label}>{label}</span>
            </a>
          </Link>
        </li>
      )}

      {!!subMenu && (
        <li
          className={cn(styles.listItem, {
            [styles.active]: isActiveParentMenuLink,
          })}
        >
          <button className={styles.link} onClick={handleSubMenuToggle}>
            <Icon name={icon} className={styles.icon} />
            <span className={styles.label}>{label}</span>
            <Icon
              name={isSubMenuVisible ? 'chevron-up' : 'chevron-down'}
              className={styles.arrowIcon}
            />
          </button>

          {isSubMenuVisible && (
            <ul className={styles.subMenu}>
              {subMenu.map((item) => {
                return (
                  <li key={item.label} className={styles.subMenuListItem}>
                    <Link href={item.href}>
                      <a
                        className={cn(styles.subMenuLink, {
                          [styles.active]: isActiveRoute(pathname, item.href),
                        })}
                      >
                        {item.label}
                      </a>
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </li>
      )}
    </>
  );
};

export default NavigationItem;
