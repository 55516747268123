// Types
import { GetAllHomePageData_getHomePageData_amas } from 'api/homePage/types/GetAllHomePageData';
// Components
import Section from 'components/common2/Section/Section';
import AmaList from 'components/common2/AmaList/AmaList';
// Constants
import { AMAS } from 'constants/routes';

type AmasProps = {
  amas: GetAllHomePageData_getHomePageData_amas[];
};

const Amas = ({ amas }: AmasProps) => {
  if (!amas?.length) {
    return null;
  }

  return (
    <Section
      title="personal videos"
      backgroundTitle="personal video"
      seeAllLink={AMAS}
      seeAllLinkTitle="View all personal videos"
    >
      <AmaList amas={amas} withCarousel />
    </Section>
  );
};

export default Amas;
