import { useQuery } from '@apollo/client';
// Api
import { GET_ALL_HOME_PAGE_DATA } from 'api/homePage/queries';
// Types
import {
  StreamStatus,
  StreamOrderBy,
  StreamFilterInput,
} from 'api/graphql-global-types';
import {
  GetAllHomePageData,
  GetAllHomePageDataVariables,
  GetAllHomePageData_upcomingStreams_entities,
  GetAllHomePageData_getHomePageData_ctaBanners,
  GetAllHomePageData_getHomePageData_watchStreams,
  GetAllHomePageData_upcomingStreamsDuplication_entities,
} from 'api/homePage/types/GetAllHomePageData';
// Hooks
import { useRefetchOnUserLogIn } from 'hooks';
// Layouts
import Main from 'layouts/Main/Main';
// Components
import Heroshot from './components/Heroshot/Heroshot';
import Athletes from './components/Athletes/Athletes';
import Amas from './components/Amas/Amas';
import Merch from './components/Merch/Merch';
import Stories from './components/Stories/Stories';
import NewsLetter from '../common2/NewsLetter/NewsLetter';
import CtaSection from './components/common/CtaSection/CtaSection';
import Watchstreams from 'components/HomePage/components/Watchstreams/Watchstreams';
// Styles
import styles from './HomePage.module.scss';

export type StreamForHomePage =
  | GetAllHomePageData_upcomingStreams_entities
  | GetAllHomePageData_getHomePageData_watchStreams
  | GetAllHomePageData_upcomingStreamsDuplication_entities;

const HomePage = () => {
  const upcomingStreamsVariables: StreamFilterInput = {
    orderBy: StreamOrderBy.ScheduleDate,
    streamStatus: [
      StreamStatus.Scheduled,
      StreamStatus.Active,
      StreamStatus.Paused,
    ],
    limit: 3,
  };

  const { data, refetch } = useQuery<
    GetAllHomePageData,
    GetAllHomePageDataVariables
  >(GET_ALL_HOME_PAGE_DATA, {
    variables: {
      upcomingStreamsInput: {
        ...upcomingStreamsVariables,
        haveSponsor: true,
      },
      upcomingStreamsDuplicationInput: {
        ...upcomingStreamsVariables,
        haveSponsor: false,
      },
    },
  });

  useRefetchOnUserLogIn({ refetch });

  const getData = (field: string) =>
    data?.getHomePageData?.[`${field}`]
      ?.slice()
      ?.sort((a, b) => a?.orderIndex - b?.orderIndex) || [];

  const merchCtaBanner:
    | GetAllHomePageData_getHomePageData_ctaBanners
    | undefined = getData('ctaBanners')[0];

  const enrollCtaBanner:
    | GetAllHomePageData_getHomePageData_ctaBanners
    | undefined = getData('ctaBanners')[1];

  const streamsFromBackOffice: StreamForHomePage[] = getData('watchStreams');

  const sponsoredStreams: StreamForHomePage[] =
    data?.upcomingStreams?.entities || [];

  const nonSponsoredStreams: StreamForHomePage[] =
    data?.upcomingStreamsDuplication?.entities || [];

  const streams: StreamForHomePage[] = [
    ...streamsFromBackOffice,
    ...sponsoredStreams,
    ...nonSponsoredStreams,
  ].slice(0, 3);

  return (
    <Main
      className={styles.homePageWrapper}
      backgroundColor="white"
      withoutSidesPadding
    >
      <Heroshot banners={getData('mainBanners')} />

      <Athletes athletes={getData('stores')} />

      {merchCtaBanner && <CtaSection cta={merchCtaBanner} type="merch" />}

      <Watchstreams streams={streams} />

      <Merch merch={getData('merch')} />

      <Stories stories={getData('athleteStories')} />

      {enrollCtaBanner && <CtaSection cta={enrollCtaBanner} type="enroll" />}

      <Amas amas={getData('amas')} />

      <NewsLetter />
    </Main>
  );
};

export default HomePage;
