import { useMutation } from '@apollo/client';
import cn from 'classnames';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
// Api
import { SAVE_EMAIL } from 'api/auth/mutations';
import { SaveEmail, SaveEmailVariables } from 'api/auth/types/SaveEmail';
// Helpers
import { yupEmailRequiredValidation } from 'helpers/validation';
// Layouts
import ResolutionWrapper from 'layouts/ResolutionWrapper/ResolutionWrapper';
// Ui2
import BackgroundTitle from 'ui2/BackgroundTitle/BackgroundTitle';
import Button from 'ui2/Button/Button';
// Components
import { showToast } from 'components/common/Toast/Toast';

import styles from './NewsLetter.module.scss';

type FormInputs = {
  email: string;
};

const validationSchema = yup.object().shape({
  email: yupEmailRequiredValidation(),
});

const NewsLetter = () => {
  const { register, handleSubmit, errors } = useForm<FormInputs>({
    resolver: yupResolver(validationSchema),
  });

  const [submitEmail, { loading }] = useMutation<SaveEmail, SaveEmailVariables>(
    SAVE_EMAIL
  );

  const handleFormSubmit: SubmitHandler<FormInputs> = async ({ email }) => {
    try {
      await submitEmail({ variables: { email } });
      showToast({
        message:
          'Thanks for signing up. We will send you an update as soon as we’re ' +
          'going live. If you need to get in touch in the meantime email ' +
          'matt@millions.co',
      });
    } catch (error) {
      showToast({
        message: error?.message,
        type: 'error',
      });
    }
  };

  return (
    <section className={styles.root}>
      <BackgroundTitle
        content="Newsletter"
        className={styles.backgroundTitle}
        color="gold"
      />

      <ResolutionWrapper>
        <h2 className={styles.headerTitle}>
          Get notified about new products, merch drops, and upcoming streams.
          Don&apos;t miss out!
        </h2>

        <form className={styles.form} onSubmit={handleSubmit(handleFormSubmit)}>
          <div className={styles.inputWrapper}>
            <input
              type="text"
              name="email"
              ref={register}
              className={cn(styles.input, {
                [styles['error']]: errors?.email?.message,
              })}
              placeholder="Your email"
            />
            {errors?.email && (
              <p className={styles.errorLabel}>{errors?.email?.message}</p>
            )}
          </div>
          <Button
            type="submit"
            className={styles.submitButton}
            color="harvest-gold"
            xs="small"
            l="default"
            loading={loading}
          >
            Subscribe
          </Button>
        </form>
        <p className={styles.description}>
          By submitting your email, you agree and give consent for MILLIONS to
          use your data for the purpose of marketing, promotion, and general
          updates related to MILLIONS and its subsidiaries.
        </p>
      </ResolutionWrapper>
    </section>
  );
};

export default NewsLetter;
