import Link from 'next/link';
// Types
import { GetStores_stores_entities } from 'api/store/types/GetStores';
import { GetAllHomePageData_getHomePageData_stores } from 'api/homePage/types/GetAllHomePageData';
// Constants
import { ATHLETES } from 'constants/routes';
// Ui
import Image from 'ui/Image/Image';
// Styles
import styles from './StoreListItem.module.scss';

type StoreListItemProps = {
  store: GetStores_stores_entities | GetAllHomePageData_getHomePageData_stores;
  storeImageLazyLoading?: boolean;
  type?: 'list' | 'carousel';
};

const StoreListItem = ({
  store,
  storeImageLazyLoading,
  type = 'list',
}: StoreListItemProps) => {
  const {
    id,
    slug,
    sports,
    storeDetails,
    hasMerch,
    hasAma,
    hasStreams,
    hasProducts,
    hasExperiences,
    hasMediaPost,
  } = store;

  const sportList = sports?.map((item) => item.name).join(', ');

  return (
    <Link
      key={`${id}_${slug}`}
      href={slug ? `/${slug}` : ATHLETES}
      prefetch={false}
    >
      <a className={styles.root}>
        <div className={styles.imageWrapper}>
          {storeDetails?.avatarURL ? (
            <Image
              src={storeDetails.avatarURL}
              alt={storeDetails.storeName || storeDetails.storeTitle || ''}
              className={styles.image}
              layout="fill"
              objectFit="cover"
              objectPosition="center top"
              priority={!storeImageLazyLoading}
              placeholder="blur"
              sizes="(max-width: 768px) 50vw, (max-width: 991px) 33vw, 20vw"
            />
          ) : (
            <p className={styles.emptyImageText}>{"Image doesn't exist"}</p>
          )}
        </div>

        <div className={styles.infoBlock}>
          <p className={styles.storeName}>
            {storeDetails?.storeName || storeDetails?.storeTitle || '---'}
          </p>

          {type === 'carousel' &&
            (hasMerch ||
              hasAma ||
              hasStreams ||
              hasProducts ||
              hasExperiences ||
              hasMediaPost) && (
              <p className={styles.products}>
                {hasMerch && <span className={styles.productsItem}>Merch</span>}

                {hasAma && (
                  <span className={styles.productsItem}>Personal Videos</span>
                )}

                {hasStreams && (
                  <span className={styles.productsItem}>Live Streams</span>
                )}

                {hasProducts && (
                  <span className={styles.productsItem}>Products</span>
                )}

                {hasExperiences && (
                  <span className={styles.productsItem}>Experiences</span>
                )}

                {hasMediaPost && (
                  <span className={styles.productsItem}>Media Feed</span>
                )}
              </p>
            )}

          {type === 'list' && (
            <p className={styles.sportName}>{sportList || '---'}</p>
          )}

          <p className={styles.viewMore}>view profile</p>
        </div>
      </a>
    </Link>
  );
};

export default StoreListItem;
