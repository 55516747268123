import { gql } from '@apollo/client';

export const GET_SPORTS = gql`
  query Sports($input: GetSportsInput) {
    sports(input: $input) {
      id
      name
      hashtags {
        id
        name
      }
    }
  }
`;

export const GET_SPORT_NAMES = gql`
  query GetSportNames($input: GetSportsInput) {
    sports(input: $input) {
      id
      name
    }
  }
`;
