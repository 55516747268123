import { createUploadLink } from 'apollo-upload-client';
import {
  split,
  from,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
// Hoc
import { errorLink } from 'hocs/withApollo';
// Helpers
import { isTestEnv } from 'helpers/env';
import { getCohostOrRegularAccessTokenFromCookies } from 'helpers/cookies';

const httpLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_ANALYTICS_API_HOST,
});

const authLink = setContext((_, { headers }) => {
  const token = getCohostOrRegularAccessTokenFromCookies();

  return {
    headers: {
      ...headers,
      // a fallback with an empty token to make a public request
      Authorization: `Bearer ${token || ''}`,
    },
  };
});

const analyticsLink = from([
  errorLink,
  authLink,
  (httpLink as unknown) as ApolloLink,
]);

const link = split(({ query }) => {
  const definition = getMainDefinition(query);

  return definition.kind === 'OperationDefinition';
}, analyticsLink);

/**
 * Add a check for test env and keep the "client" as "undefined"
 * as there is no clear way to mock an apollo client instance
 */
export default isTestEnv
  ? undefined
  : new ApolloClient({
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          nextFetchPolicy: 'cache-first',
        },
      },
      cache: new InMemoryCache(),
      link,
    });
